import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
import { MobileMenu, MobileMenuItem } from '@hse-design/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "mobilemenu"
    }}>{`MobileMenu`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { MobileMenu, MobileMenuItem } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks name={'MobileMenu'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=29703%3A37439'} storybook={'/?path=/story/navigation-mobilemenu--playground'} vueStorybook={'/?path=/story/navigation-mobilemenu--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`MobileMenu – компонент меню для мобильных устройств. Позволяет пользователю перемещаться между разными разделами продукта. Мобильное меню следует использовать вместе с компонентом `}<a parentName="p" {...{
        "href": "/src-components-header"
      }}>{`Header`}</a>{`.`}</p>
    <p>{`В качестве пунктов меню следует использовать компонент MobileMenuItem.`}</p>
    <p>{`Интерактивный пример использования MobileMenu находится в сторибуке.`}</p>
    <p>{`Код использования:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`
    const items = [
      {
        id: 1,
        icon: IconSectionMain,
        name: 'Главная'
      },
      {
        id: 2,
        icon: IconGlobalMessage,
        name: 'Сообщения',
        label: '12'
      },
      {
        id: 3,
        icon: IconSectionEducation,
        name: 'Курсы'
      },
      {
        id: 4,
        icon: IconGlobalCalendar,
        name: 'Календарь'
      },
      {
        id: 5,
        icon: IconGlobalList,
        name: 'Файлы'
      },
      {
        id: 6,
        icon: IconSectionTask,
        name: 'Задачи'
      },
      {
        id: 7,
        icon: IconSectionCorporate,
        name: 'Проекты'
      }
    ];
    const [selectedItem, setSelectedItem] = useState(1);
    const [isOpen, setIsOpen] = useState(true);
    return (<>
      <Header logo={<LogoDesignSystem width={'144px'}/>} onHamburgerClick={() => setIsOpen((v) => !v)} />
      <MobileMenu
        open={isOpen}
      >
        {items.slice(0, 6).map((item) => (
          <MobileMenuItem
            key={item.id}
            icon={item.icon}
            selected={item.id === selectedItem}
            label={item.label}
            onClick={() => setSelectedItem(item.id)}
          >
            {item.name}
          </MobileMenuItem>
        ))}
        <Divider />
        {items.slice(6).map((item) => (
          <MobileMenuItem
            key={item.id}
            icon={item.icon}
            selected={item.id === selectedItem}
            label={item.label}
            onClick={() => setSelectedItem(item.id)}
          >
            {item.name}
          </MobileMenuItem>
        ))}
      </MobileMenu>
    </>)
`}</code></pre>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=30069%3A42909' height={1000} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=30069%3A42853' height={800} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=30235%3A40951' height={1000} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы-mobilemenu"
    }}>{`Пропы MobileMenu`}</h2>
    <Props of={MobileMenu} mdxType="Props" />
    <h2 {...{
      "id": "пропы-mobilemenuitem"
    }}>{`Пропы MobileMenuItem`}</h2>
    <Props of={MobileMenuItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      